import React from "react";
import { RouteProps } from "react-router-dom";
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from "../menu";
import DefaultHeader from "../pages/_layout/_headers/DefaultHeader";
import StudentHeader from "../pages/_layout/_headers/StudentHeader";

const headers: RouteProps[] = [
  { path: "/dashboard", element: <DefaultHeader /> },
  { path: "/students/*", element: <DefaultHeader /> },
  { path: "/exams/*", element: <DefaultHeader /> },
  { path: "/flights/*", element: <DefaultHeader /> },
  { path: "/tickets/*", element: <DefaultHeader /> },
  { path: "/student", element: <StudentHeader /> },
  { path: "/exam/*", element: null },
  {
    path: `*`,
    element: null,
  },
];

export default headers;
