import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import Popovers from "../../../components/bootstrap/Popovers";
import Button, { IButtonProps } from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import LANG, { getLangWithKey, ILang } from "../../../lang";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import Spinner from "../../../components/bootstrap/Spinner";
import Avatar from "../../../components/Avatar";
import { Icons } from "react-toastify";
import Navigation from "../../../layout/Navigation/Navigation";
import useDeviceScreen from "../../../hooks/useDeviceScreen";
import Logo from "../../../components/Logo";

export const studentHeaderMenuItems = {
  blank: {
    id: "blank",
    text: "Sınavlarım",
    path: "student",
    icon: "Task",
  },
};

const logout = () => {
  localStorage.clear();
  window.location.href = '/';
};

const DashboardHeader = () => {
  const { width } = useDeviceScreen();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? "dark" : "light",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };

  /**
   * Language attribute
   */

  return (
    <Header>
      <HeaderLeft>
        <div className="d-flex align-items-center">
          <div className="me-5">
            <Logo height={32} />
          </div>
          <Navigation menu={{ ...studentHeaderMenuItems }} id="header-top-menu" horizontal={!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)} />
        </div>
      </HeaderLeft>
      <HeaderRight>
        <div className="row g-3 align-items-center">
          {/* Dark Mode */}
          <div className="col-auto me-3">
            <Popovers trigger="hover" desc="Gece / Gündüz Modu">
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...styledBtn}
                onClick={() => setDarkModeStatus(!darkModeStatus)}
                className="btn-only-icon"
                data-tour="dark-mode"
                aria-label="Toggle dark mode">
                <Icon icon={darkModeStatus ? "DarkMode" : "LightMode"} color={darkModeStatus ? "info" : "warning"} className="btn-icon" />
              </Button>
            </Popovers>
          </div>
          {/* Lang Selector */}
          <div className="col-auto">
            <Dropdown>
              <DropdownToggle hasIcon={false}>
                <Button className="p-0" isActive={true} style={{ border: 0 }}>
                  <div className="col d-flex align-items-center">
                    <div>
                      <div className="fw-bold fs-6 mb-0 me-3">{localStorage.getItem("user")}</div>
                      <div className="text-muted"></div>
                    </div>
                    <div className="me-0">
                      <Avatar src={`https://api.dicebear.com/8.x/initials/svg?fontWeight=700&fontSize=35&size=100&font=Trebuchet MS&seed=${localStorage.getItem("user")}`} size={48} color="primary" />
                    </div>
                  </div>
                </Button>
              </DropdownToggle>
              <DropdownMenu isAlignmentEnd data-tour="lang-selector-menu">
                <DropdownItem>
                  <Button onClick={logout} icon={"Logout"}>Çıkış Yap</Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </HeaderRight>
    </Header>
  );
};

export default DashboardHeader;
