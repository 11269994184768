import React from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Popovers from '../../../components/bootstrap/Popovers';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Avatar from '../../../components/Avatar';
import Icon from '../../../components/icon/Icon';

const DefaultHeader = () => {
	
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { width } = useDeviceScreen();
	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={{ ...pageLayoutTypesPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<HeaderRight>
        <div className="row g-3 align-items-center">
          {/* Dark Mode */}
          <div className="col-auto me-3">
            <Popovers trigger="hover" desc="Gece / Gündüz Modu">
              <Button
                onClick={() => setDarkModeStatus(!darkModeStatus)}
                className="btn-only-icon"
                data-tour="dark-mode"
                aria-label="Toggle dark mode">
                <Icon icon={darkModeStatus ? "DarkMode" : "LightMode"} color={darkModeStatus ? "info" : "warning"} className="btn-icon" />
              </Button>
            </Popovers>
          </div>
          {/* Lang Selector */}
          <div className="col-auto">
            <Dropdown>
              <DropdownToggle hasIcon={false}>
                <Button className="p-0" isActive={true} style={{ border: 0 }}>
                  <div className="col d-flex align-items-center">
                    <div>
                      <div className="fw-bold fs-6 mb-0 me-3">{localStorage.getItem("user")}</div>
                      <div className="text-muted">
                      </div>
                    </div>
                    <div className="me-0">
                      <Avatar src={`https://api.dicebear.com/8.x/initials/svg?fontWeight=700&fontSize=35&size=100&font=Trebuchet MS&seed=${localStorage.getItem('user')}`} size={48} color="primary" />
                    </div>
                  </div>
                </Button>
              </DropdownToggle>
              <DropdownMenu isAlignmentEnd data-tour="lang-selector-menu">
                <DropdownItem>
                  <Button icon={"Logout"}>Çıkış Yap</Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
