import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';
const DefaultFooter = () => {
	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
					</div>
					<div className='col-auto'>
						<Popovers
							title='ISBN: 978-605-64733-1-9'
							
							desc={<div style={{textAlign: 'center'}}>Uluslararası Test Komisyonu (ITC) kriterlerine göre hazırlanmıştır.<br /><br /><code>Coded with ❤️ by Abish</code></div>}>
							2024 © Praxis Havacılık & Uzay Programları v.2.1.0
						</Popovers>
						
					</div>
				</div>
			</div>
		</Footer>
	);
};

export default DefaultFooter;
