import React, { lazy } from 'react';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';


const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const PAGES = {
	EXAMS: lazy(() => import('../pages/dashboard/exams/list')),
	EXAMS_CREATE: lazy(() => import('../pages/dashboard/exams/create')),
	EXAMS_PREVIEW: lazy(() => import('../pages/dashboard/exams/preview')),
	EXAMS_EDIT: lazy(() => import('../pages/dashboard/exams/edit')),

	STUDENTS: lazy(() => import('../pages/dashboard/students/list')),
	STUDENTS_EDIT: lazy(() => import('../pages/dashboard/students/edit')),

	FLIGHTS: lazy(() => import('../pages/dashboard/flights/list')),

	STUDENT: lazy(() => import('../pages/palaz/studentExams')),
	EXAM: lazy(() => import('../pages/palaz/exam')),
	TICKETS_CREATE: lazy(() => import('../pages/dashboard/tickets/create')),
	LOGIN: lazy(() => import('../pages/presentation/auth/Login')),
}
const presentation = [
	{
		path: '/exams',
		element:  <PAGES.EXAMS />,
	},
	{
		path: '/exams/new',
		element:  <PAGES.EXAMS_CREATE />,
	},
	{
		path: '/exams/:id',
		element:  <PAGES.EXAMS_EDIT />,
	},
	{
		path: '/exams/:id/preview',
		element:  <PAGES.EXAMS_PREVIEW />,
	},

	{
		path: '/students',
		element:  <PAGES.STUDENTS />,
	},
	{
		path: '/students/:id',
		element:  <PAGES.STUDENTS_EDIT />,
	},

	

	{
		path: '/flights',
		element:  <PAGES.FLIGHTS />,
	},

	{
		path: '/exam/:id',
		element:  <PAGES.EXAM />,
	},
	{
		path: '/tickets/new',
		element:  <PAGES.TICKETS_CREATE />,
	},
	{
		path: '/student',
		element:  <PAGES.STUDENT />,
	},
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <PAGES.LOGIN />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: "*",
		element: <AUTH.PAGE_404 />,
	},
];
const contents = [...presentation];

export default contents;
