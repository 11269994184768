import React, { FC } from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/img/logo.png';
interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<img width={width} height={height} src={logo} />	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

export default Logo;
